import React from 'react';

const Menu = () => {
  return (
    <div className="d-flex justify-content-start w-100">
      <ul className="navbar-nav mx-auto flex-grow-1">
        <li className="nav-item">
          <a className="nav-link nav-an" href="/core">
            Core Network
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-an" href="/zeta">
            Zeta Chain
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-an" href="/base">
            Base Blockchain
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-an" href="/fantom">
            Fantom
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
