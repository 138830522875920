import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Base from './base';
import Core from './core';
import Fantom from './fantom';
import Zeta from './zeta';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';


const App = () => {

  // 1. Get projectId
  const projectId = process.env.REACT_APP_PROJECT_ID;

  // 2. Set chains
  const bsc = {
    chainId: 56,
    name: 'BSC',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }
  const base = {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
  }
  const core = {
    chainId: 1116,
    name: 'Core',
    currency: 'CORE',
    explorerUrl: 'https://scan.coredao.org',
    rpcUrl: 'https://core.drpc.org'
  }
  const fantom = {
    chainId: 250,
    name: 'Fantom',
    currency: 'FTM',
    explorerUrl: 'https://ftmscan.com',
    rpcUrl: 'https://rpc.fantom.network'
  }
  const zeta = {
    chainId: 7000,
    name: 'ZetaChain',
    currency: 'ZETA',
    explorerUrl: 'https://explorer.zetachain.com',
    rpcUrl: 'https://zeta-chain.drpc.org'
  }

  // 3. Create a metadata object
  const metadata = {
    name: 'eDollars USDT',
    description: 'eDollars Pegged USDT for EVMs',
    url: 'https://edollars.org', // origin must match your domain & subdomain
    icons: ['https://edollars.org/icons/']
  }

  // 4. Create Ethers config
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    //rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 56 // used for the Coinbase SDK
  })

  // 5. Create a Web3Modal instance
  createWeb3Modal({
    ethersConfig,
    chains: [bsc,base,core,fantom,zeta],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Core />} />
        <Route path="/base" element={<Base />} />
        <Route path="/core" element={<Core />} />
        <Route path="/zeta" element={<Zeta />} />
        <Route path="/fantom" element={<Fantom />} />
      </Routes>
    </Router>
  );
};

export default App;
