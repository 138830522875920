import React, { useState, useEffect } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import swapContractABI from './swapContractABI.json';
import ERC20ABI from './ERC20ABI.json';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Modals from "./components/modals";
import axios from 'axios';
import Loading from './loading';
import LoadingModal from './loadingModal';
import Menu from './components/menu';
import { BrowserProvider, Contract, parseUnits, formatUnits, formatEther, JsonRpcProvider } from 'ethers';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from '@web3modal/ethers/react';


function Zeta() {

  // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

  const usdtAddress = process.env.REACT_APP_USDT_BSC;
  const tokenContract = process.env.REACT_APP_USDT_ZETA;
  const swapFee = process.env.REACT_APP_FEE;
  const swapFee2 = process.env.REACT_APP_FEE_ZETA;
  const swapContract = process.env.REACT_APP_SWAP_CONTRACT1_ZETA;
  const swapContract2 = process.env.REACT_APP_SWAP_CONTRACT2_ZETA;
  const xToken = process.env.REACT_APP_PKEY;
  const toAddress = process.env.REACT_APP_To_ADDRESS;


  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [usdtContractBalance, setUsdtContractBalance] = useState(0);
  const [tokenContractBalance, setTokenContractBalance] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [swapButtonText, setSwapButtonText] = useState("Convert");
  const [loadingMessage, setLoadingMessage] = useState(" ");

  const tokenSymbol = "USDT";
  const title = "Convert USDT (Bsc - Zeta)";
  const minSwap = 1;



  const handleUsdtAmountChange = (event) => {
    setUsdtAmount(event.target.value);
    if(event.target.value > 0){
      setTokenAmount(event.target.value);
    }
  };

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
    if(event.target.value > 0){
      setUsdtAmount(event.target.value);
    }
  };


  useEffect(() => {

  }, []);

  const handelDirection = () => {
    setDirection(!direction);
    setTokenAmount(0);
    setUsdtAmount(0);
  }

  async function addToken() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Currently only supports ERC20 tokens
          options: {
            address: tokenContract, // The token's contract address
            symbol: tokenSymbol,   // A string symbol of the token
            decimals: 18,    // The number of decimals the token uses
            image: window.location.href + '/img/token-logo.png',  // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Token was added successfully!');
      } else {
        console.log('Token was not added.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const bscUrl = 'https://bscscan.com/token/' + usdtAddress;
  const chainUrl = 'https://explorer.zetachain.com/address/' + tokenContract;



  const swapTokens = async() => {
    if (usdtAmount < minSwap && !direction) {
      alert("Enter at least " + minSwap + " " + tokenSymbol);
      return;
    }
    if (tokenAmount < minSwap && direction) {
      alert("Enter at least " + minSwap + " " + tokenSymbol);
      return;
    }


      if(isConnected){
        setIsLoading(true);
        if(!direction){
          // Convert from BSC to Another Chain
          if(chainId !== 56){
            switchNetwork(56);
            setIsLoading(false);
            return;
          }
          setLoadingMessage('Checking your wallet balance');
          const provider = new BrowserProvider(walletProvider);
          const signer = await provider.getSigner();
          const contract = new Contract(swapContract, swapContractABI, signer);
          //const tokenContractApproval = new Contract(tokenContract, ERC20ABI, signer);
          const usdtContractApproval = new Contract(usdtAddress, ERC20ABI, signer);

          // Get the USDT balance
          const usdtBal = new Contract(usdtAddress, ERC20ABI, provider);
          const balance = await usdtBal.balanceOf(address);

          if(formatUnits(balance, 18) < parseInt(usdtAmount,10)){
            alert('Not enough ' + tokenSymbol + ' balance in your wallet');
            setIsLoading(false);
            return;
          };
          // if(tokenContractBalance < parseInt(usdtAmount,10)){
          //   alert('Not enough ' + tokenSymbol + ' tokens in the liquidity pool');
          //   return;
          // };

          try {
            // Approve the swapContract to spend tokens
            console.log(usdtAmount);
            console.log(formatUnits(usdtAmount, 18));
            setLoadingMessage('Approve withdraw ' + tokenSymbol + ' from your wallet');
            const approvalTx = await usdtContractApproval.approve(swapContract, parseUnits(usdtAmount, 18));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Deposit USDT
            setLoadingMessage('Confirm the transaction');
            const tx = await contract.deposit(
              parseUnits(usdtAmount, 18),
              { value: swapFee}
            );
            console.log('Transaction sent:', tx);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx.wait();
            console.log('Transaction confirmed:', tx);

            //********** Withdraw Pegged USDT
            setLoadingMessage('Change the network fron BSC to Core network');
            await switchNetwork(7000);
            const provider2 = new BrowserProvider(walletProvider);
            const signer2 = await provider2.getSigner();
            const contract2 = new Contract(swapContract2, swapContractABI, signer2);
            console.log(parseUnits(usdtAmount, 18));
            console.log(xToken);
            console.log(toAddress);
            setLoadingMessage('Confirm the transaction');
            const tx2 = await contract2.convert(
              parseUnits(usdtAmount, 18),
              xToken,
              toAddress,
              { value: swapFee2}
            );
            console.log('Transaction sent:', tx2);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx2.wait();
            console.log('Transaction confirmed:', tx2);

            setLoadingMessage(' ');
            setSwapButtonText('Convert');
            setIsLoading(false);
            alert('Congratulations, Convert transaction confirmed');
          } catch (error) {
            setLoadingMessage(' ');
            setSwapButtonText('Convert');
            setIsLoading(false);
            console.error('Convert failed', error);
            alert('USDT convert failed');
          }
        }else{
          if(chainId !== 7000){
            switchNetwork(7000);
            setIsLoading(false);
            return;
          }
          setLoadingMessage('Checking your wallet balance');
          // Get the token balance
          const provider = new BrowserProvider(walletProvider);
          const signer = await provider.getSigner();
          const contract = new Contract(swapContract2, swapContractABI, signer);
          const tokenContractApproval = new Contract(tokenContract, ERC20ABI, signer);
          //const usdtContractApproval = new Contract(usdtAddress, ERC20ABI, signer);

          const contractBal = new Contract(tokenContract, ERC20ABI, provider);
          const tokenBal = await contractBal.balanceOf(address);

          if(formatUnits(tokenBal, 18) < parseInt(tokenAmount, 10)){
            alert('Not enough ' + tokenSymbol + ' balance in your wallet');
            setIsLoading(false);
            return;
          };
          // if(usdtContractBalance < parseInt(tokenAmount, 10)){
          //   alert('Not enough USDT in the liquidity pool');
          //   return;
          // };

          try {
            // Approve the swapContract to spend tokens
            console.log('start approval');
            console.log(tokenAmount);
            console.log(parseUnits(tokenAmount, 18));
            setLoadingMessage('Approve withdraw ' + tokenSymbol + ' from your wallet');
            const approvalTx = await tokenContractApproval.approve(swapContract2, parseUnits(tokenAmount, 18));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Swap tokens for USDT
            setLoadingMessage('Confirm the transaction');
            const tx = await contract.deposit(
              parseUnits(tokenAmount, 18),
              {value: swapFee2}
            );
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            console.log('Transaction sent:', tx);
            await tx.wait();
            console.log('Transaction confirmed:', tx);

            //********** Withdraw BSC USDT
            setLoadingMessage('Change the network from Core network to BSC');
            await switchNetwork(56);
            const provider2 = new BrowserProvider(walletProvider);
            const signer2 = await provider2.getSigner();
            const contract2 = new Contract(swapContract, swapContractABI, signer2);
            console.log(parseUnits(usdtAmount, 18));
            console.log(xToken);
            console.log(toAddress);
            setLoadingMessage('Confirm the transaction');
            const tx2 = await contract2.convert(
              parseUnits(usdtAmount, 18),
              xToken,
              toAddress,
              { value: swapFee}
            );
            console.log('Transaction sent:', tx2);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx2.wait();
            console.log('Transaction confirmed:', tx2);

            setLoadingMessage(' ');
            setSwapButtonText('Convert');
            setIsLoading(false);
            alert('Congratulations, Convert transaction confirmed');
          } catch (error) {
            setLoadingMessage(' ');
            setSwapButtonText('Convert');
            console.error('Convert failed', error);
            setIsLoading(false);
            alert('Convert failed');
          }
        }

    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={() => open()} className="curr-btn btn-connect">Connect Wallet</button>
    )
  }

  const swapButton = () => {
    return (
      <button onClick={swapTokens} className="curr-btn btn-connect">{swapButtonText}</button>
    )
  }



  return (
    <>
      <nav className="navbar navbar-expand-sm flex-sm-nowrap flex-wrap">
        <div className="container-fluid">
          <button
            className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar5"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <span className="navbar-brand">
            <a href="/">
              <img src="img/logo.png" height={50} width={200} alt="Add to MetaMask"/>
            </a>
          </span>

          <div className="navbar-collapse collapse" id="navbar5">

            <Menu />

            <div className="d-flex w-100 justify-content-end">
              <div className="border-box-s d-flex flex-row align-item-center py-2">
                <div className="border-box-s d-flex">

                        <div className="textfirst">
                          <w3m-button />
                        </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </nav>


              <div className="card-st">
                <div className="card-d">
                <LoadingModal isLoading={isLoading} message={loadingMessage} />

                    <main className="card-d-bg">
                      <div className="border-boxing flex-adjust-d space-s-between ma-color px-2 pb-1">
                        <div className="d-flex gap-2">
                          <div className="s-color pad-link"><h4>{title}</h4></div>
                        </div>

                        <div className="border-boxing flex-adjust-d width-fit-d justify-content-end">
                          <div className="position-relative">

                            <div className="dropdown">
                              <img src="img/token-logo.png" height={24} width={24} alt="Token logo"/>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div style={{display:'flex', flexDirection: `${direction ? 'column-reverse' : 'column'}`}}>
                        <div className="swap-card" id="from_token_select">
                          <div className="flex-wrap-d">
                            <div className="input-contain">
                              <div className="flex-ne">
                                <input
                                  className="input-swap-d input-d-swp"
                                  type="text"
                                  placeholder="0"
                                  value={direction? parseFloat(usdtAmount) : usdtAmount}
                                  onChange={handleUsdtAmountChange}
                                />
                                <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={bscUrl} target='_blank' rel="noreferrer"><img src="img/chain1.png" height={32} width={32} alt="USDT Contract"/></a>
                                  </div>
                                  <span className="currency-text">{tokenSymbol}</span>
                                </div>
                              </div>
                              <div className="amount-div amt-d">
                                <div className="border-boxing flex-adjust-d space-s-between">
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="arrow-div" onClick={handelDirection}>
                          <button
                            // onClick={this.switchTokens}
                            id="swap_token_address"
                            className="arrow-swap"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#5D6785"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <polyline points="19 12 12 19 5 12"></polyline>
                            </svg>
                          </button>
                        </div>

                        <div className="swap-card" id="to_token_select">
                            <div className="flex-wrap-d">
                              <div className="input-contain">
                                <div className="flex-ne">
                                  <input
                                    className="input-swap-d input-d-swp"
                                    placeholder="0"
                                    value={direction? tokenAmount : parseFloat(tokenAmount)}
                                    onChange={handleTokenAmountChange}
                                  />
                                  <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={chainUrl} target='_blank' rel="noreferrer"><img src="img/zeta.png" height={32} width={32} alt="Add to MetaMask"/></a>
                                    </div>
                                    <span className="currency-text">{tokenSymbol}</span>
                                  </div>
                                </div>
                                <div className="amount-div amt-d">
                                  <div className="border-boxing flex-adjust-d space-s-between">
                                    <div className=" "></div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="input-div-s">
                        <div>

                        </div>


                        <div className="py-2">
                          <div className="accordion accordion-st" id="details">
                            <div className="accordion-item accordion-itm">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed text-white accord-btn"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Swap Details
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#details"
                              >
                                <div className="accordion-body pt-0">
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Rate:</p>
                                    <p className="m-0 text-grey">1 : 1</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Minmum Swap:</p>
                                    <p className="m-0 text-grey">${minSwap}</p>
                                  </div>
                                  <hr className="my-1 border-s-white" />

                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Fee</p>
                                    <p className="m-0 text-grey">{formatEther(swapFee)} BNB</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white"></p>
                                    <p className="m-0 text-grey">{formatEther(swapFee2)} ZETA</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          {isConnected ? swapButton() : connectWalletButton()}
                        </div>
                      </div>
                    </main>

                </div>
              </div>

      <Modals />
    </>
  );
}

export default Zeta;
