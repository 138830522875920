// LoadingModal.js
import React from 'react';

const LoadingModal = ({ isLoading, message }) => {
  if (!isLoading) return null; // Don't render if not loading

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.spinner}></div>
        <p>Please Wait...</p>
        <p style={styles.text}>{message}</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modal: {
    width: '300px',
    height: '200px',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    width: '55px',
    height: '55px',
    border: '5px solid lightgray',
    borderTop: '5px solid blue',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  text: {
    color: 'green', // Make text red
    fontWeight: 'bold', // Make text bold
    marginTop: '10px',
  },
};

export default LoadingModal;
